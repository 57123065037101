import React from "react";
import "./About.css";
import { useState } from "react";

const drAlina = () => {
  return (
    <article>
      <p>
        Dr. Alina Aga, a Brooklyn native, earned her Doctorate from the NYU
        College of Dentistry. She further advanced her training by completing
        the Advanced Education in General Dentistry program at NYU Langone.
        Known for her exceptional patient care, Dr. Aga has earned great
        admiration from her patients.
      </p>
      <p>
        Outside of work, Dr. Aga enjoys spending time with her family and her
        two young children, who keep her very busy. She is a proud member of the
        American Dental Association (ADA), the Second District Dental Society,
        and the New York State Dental Association (NYSDA).
      </p>
      <p>
        Dr. Aga's goal is to help her patients feel comfortable and leave with
        confidence. Having grown up with a dental phobia herself, she
        understands the importance of educating her patients every step of the
        way.
      </p>
    </article>
  );
};

const drAndrii = () => {
  return (
    <article>
      <p>
        Dr. Andrii Slobodian, is an experienced dentist with advanced education
        in Orofacial Pain. Originally from Ukraine, Dr. Slobodian completed two
        years of oral surgery residency before moving to the United States,
        where he graduated from SUNY at Buffalo School of Dental Medicine.
      </p>
      <p>
        {" "}
        Dr. Andrii provides advanced care for restorative needs, including tooth
        loss. He places all types of dental implants and has the expertise to
        address complex dental problems with leading oral surgical solutions.
      </p>
      <p>
        Dr. Andrii was previously a professional soccer player and remains an
        active family man in his free time. He has two daughters and two sons.
        On the weekends, he enjoys spending time with family, fishing, and
        hiking.
      </p>
    </article>
  );
};

const DoctorSection = ({ doctor }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <section className="doctor-container">
      <div className="doctor-profile">
        <div className="profile-image-overlay">
          <img
            src={isHovered ? doctor.flippedImage : doctor.image}
            alt={`portrait of ${doctor.name}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
          <div className="profile-details">
            <h2>{doctor.name}</h2>
            <p>{doctor.title}</p>
          </div>
        </div>
      </div>

      <div className="doctor-about">{doctor.description}</div>
    </section>
  );
};

const About = () => {
  const doctors = [
    {
      name: "Alina Draganchyuk Aga, DDS",
      title: "General Dentist & Cosmetic Dentist",
      description: drAlina(),
      image: "/imgs/dr-alina-photo-small.png",
      flippedImage: "/imgs/dr-alina-photo-small.png",
    },
  ];

  return (
    <main className="center-container fade-in">
      {doctors.map((doctor, index) => DoctorSection({ doctor }))}
    </main>
  );
};

export default About;
